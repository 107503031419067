import React from "react"
import { appImages, headerIcons } from "../../cdnImages"

import styles from "./index.module.css"

const CMSAssets = () => {
    return (
        <div className={styles.rootContainer}>
            <h3 className="text-center">Header Images</h3>
            <div className={`flex gap_20 flex_wrap ${styles.imgContainer}`}>
                {Object.keys(headerIcons).map(key => (
                    <div key={key} className={styles.iconContainer}>
                        <img src={headerIcons[key]} alt={key} />
                        <p>{key}</p>
                    </div>
                ))}
            </div>

            <h3 className={`text-center ${styles.mt40}`}>App Images</h3>
            <div className={`flex gap_20 flex_wrap ${styles.imgContainer}`}>
                {Object.keys(appImages).map(key => (
                    <div key={key} className={styles.iconContainer}>
                        <img src={appImages[key]} alt={key} />
                        <p>{key}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default CMSAssets
