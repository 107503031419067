const URL = process.env.REACT_APP_IMAGES_URL

export const headerIcons = {
    dashboard: `${URL}icons/dashboard.svg`,
    dashboardWhite: `${URL}icons/dashboard_white.svg`,
    company: `${URL}icons/company.svg`,
    companyWhite: `${URL}icons/company_white.svg`,
    charger: `${URL}icons/charger.svg`,
    chargerWhite: `${URL}icons/charger_white.svg`,
    user: `${URL}icons/user.svg`,
    userWhite: `${URL}icons/user_white.svg`,
    billings: `${URL}icons/billings.svg`,
    billingsWhite: `${URL}icons/billings_white.svg`,
    coupons: `${URL}icons/coupons.svg`,
    couponsWhite: `${URL}icons/coupons_white.svg`,
    complaints: `${URL}icons/complaints.svg`,
    complaintsWhite: `${URL}icons/complaints_white.svg`,
    auto_trigger_icon: `${URL}icons/auto_trigger_icon.svg`,
    auto_trigger_white_icon: `${URL}icons/auto_trigger_white_icon.svg`,
    subscription: `${URL}icons/subscription.svg`,
    subscription_white: `${URL}icons/subscription_white.svg`,
}

export const appImages = {
    404: `${URL}404.svg`,
    "500_img": `${URL}500_img.svg`,
    accordion: `${URL}accordion.svg`,
    admins_icon: `${URL}admins_icon.svg`,
    attach: `${URL}attach.svg`,
    available_charger: `${URL}available_charger.svg`,
    avatar: `${URL}avatar.svg`,
    back_arrow: `${URL}back_arrow.svg`,
    back_charger_icon: `${URL}back_charger_icon.svg`,
    blue_accordion: `${URL}blue_accordion.svg`,
    bookings: `${URL}bookings.svg`,
    buy_license: `${URL}buy_license.svg`,
    calender_gray_icon: `${URL}calender_gray_icon.svg`,
    calender_icon_2: `${URL}calender_icon_2.svg`,
    charge: `${URL}charge.svg`,
    charger: `${URL}charger.svg`,
    charger_gray: `${URL}charger_gray.svg`,
    charger_health_amc_icon: `${URL}charger_health_amc_icon.svg`,
    charger_health_insurance_icon: `${URL}charger_health_insurance_icon.svg`,
    charger_health_plug_icon: `${URL}charger_health_plug_icon.svg`,
    charger_health_warrenty_icon: `${URL}charger_health_warrenty_icon.svg`,
    charger_pin: `${URL}charger_pin.svg`,
    charging: `${URL}charging.svg`,
    circular_minus: `${URL}circular_minus.svg`,
    circular_plus: `${URL}circular_plus.svg`,
    client_icon: `${URL}client_icon.svg`,
    clipboard: `${URL}clipboard.svg`,
    close: `${URL}close.svg`,
    close_icon_black: `${URL}close_icon_black.svg`,
    close_modal_icon: `${URL}close_modal_icon.svg`,
    colored_calender_icon: `${URL}colored_calender_icon.svg`,
    comment_admin: `${URL}comment_admin.svg`,
    companyDetailsTopInfoIcon1: `${URL}companyDetailsTopInfoIcon1.svg`,
    companyDetailsTopInfoIcon2: `${URL}companyDetailsTopInfoIcon2.svg`,
    company_host: `${URL}company_host.svg`,
    complaints_icon: `${URL}complaints_icon.svg`,
    complaints_solved: `${URL}complaints_solved.svg`,
    completed_billing: `${URL}completed_billing.svg`,
    completed_bookings: `${URL}completed_bookings.svg`,
    connected: `${URL}connected.svg`,
    copy_access_icon: `${URL}copy_access_icon.svg`,
    cpo_emsp_icon: `${URL}cpo_emsp_icon.svg`,
    cpo_icon: `${URL}cpo_icon.svg`,
    crossIconRed: `${URL}crossIconRed.svg`,
    csv: `${URL}csv.svg`,
    currency_icon: `${URL}currency_icon.svg`,
    current_location: `${URL}current_location.svg`,
    cursor: `${URL}cursor.svg`,
    default_file: `${URL}default_file.svg`,
    delete_icon_subrow: `${URL}delete_icon_subrow.svg`,
    diagonal: `${URL}diagonal.svg`,
    disconnected: `${URL}disconnected.svg`,
    dots: `${URL}dots.svg`,
    download_icon_2: `${URL}download_icon_2.svg`,
    down_arrow_icon: `${URL}down_arrow_icon.svg`,
    delink_icon: `${URL}delink_icon.svg`,
    edit: `${URL}edit.svg`,
    edit__icon: `${URL}edit__icon.svg`,
    emsp_gray_icon: `${URL}emsp_gray_icon.svg`,
    eMSP_icon: `${URL}eMSP_icon.svg`,
    energy_charging_icon: `${URL}energy_charging_icon.svg`,
    error: `${URL}error.svg`,
    error_screen: `${URL}error_screen.svg`,
    ev_owners: `${URL}ev_owners.svg`,
    ev_charger: `${URL}ev_charger.svg`,
    exist: `${URL}exist.svg`,
    failed_billing: `${URL}failed_billing.svg`,
    fileDeleteIcon: `${URL}fileDeleteIcon.svg`,
    fileUpload: `${URL}fileUpload.svg`,
    filter_icon_2: `${URL}filter_icon_2.svg`,
    fleet: `${URL}fleet.svg`,
    fleet_gray_icon: `${URL}fleet_gray_icon.svg`,
    folder_icon: `${URL}folder_icon.svg`,
    green_arrow_icon: `${URL}green_arrow_icon.svg`,
    green_check_icon: `${URL}green_check_icon.svg`,
    green_rounded_tick: `${URL}green_rounded_tick.svg`,
    green_tick: `${URL}green_tick.svg`,
    high_priority: `${URL}high_priority.svg`,
    hub_icon: `${URL}hub_icon.svg`,
    info: `${URL}info.svg`,
    info_icon: `${URL}info_icon.svg`,
    inreview_status: `${URL}inreview_status.svg`,
    invalid: `${URL}invalid.svg`,
    investor_icon: `${URL}investor_icon.svg`,
    invoice_icon: `${URL}invoice_icon.svg`,
    kWh: `${URL}kWh.svg`,
    kW_icon: `${URL}kW_icon.svg`,
    left_arrow: `${URL}left_arrow.svg`,
    left_double_arrow: `${URL}left_double_arrow.svg`,
    left_pagination_arrow: `${URL}left_pagination_arrow.svg`,
    login__graphic: `${URL}login__graphic.svg`,
    low_priority: `${URL}low_priority.svg`,
    last_profit_loss: `${URL}last_profit_loss.svg`,
    medium_priority: `${URL}medium_priority.svg`,
    money: `${URL}money.svg`,
    money_icon: `${URL}money_icon.svg`,
    no_comments: `${URL}no_comments.svg`,
    no_trans: `${URL}no_trans.svg`,
    ongoing_billing: `${URL}ongoing_billing.svg`,
    ongoing_charging: `${URL}ongoing_charging.svg`,
    payment_filter: `${URL}payment_filter.svg`,
    password_eye: `${URL}password_eye.svg`,
    pdf: `${URL}pdf.svg`,
    pdf_placeholder: `${URL}pdf_placeholder.svg`,
    plug: `${URL}plug.svg`,
    profile_edit_icon: `${URL}profile_edit_icon.svg`,
    redirect: `${URL}redirect.svg`,
    red_cross: `${URL}red_cross.svg`,
    refresh_icon: `${URL}refresh_icon.svg`,
    reload_icon: `${URL}reload_icon.svg`,
    renew_license: `${URL}renew_license.svg`,
    reset_icon: `${URL}reset_icon.svg`,
    reset_icon_2: `${URL}reset_icon_2.svg`,
    revenue_dashboard_icon: `${URL}revenue_dashboard_icon.svg`,
    red_arrow: `${URL}red_arrow.svg`,
    rfid_icon: `${URL}rfid_icon.svg`,
    right_arrow: `${URL}right_arrow.svg`,
    right_double_arrow: `${URL}right_double_arrow.svg`,
    right_pagination_arrow: `${URL}right_pagination_arrow.svg`,
    rounded_close_icon: `${URL}rounded_close_icon.svg`,
    rupee_icon: `${URL}rupee_icon.svg`,
    save: `${URL}save.svg`,
    search: `${URL}search.svg`,
    search_icon: `${URL}search_icon.svg`,
    send_mail: `${URL}send_mail.svg`,
    settings_icon: `${URL}settings_icon.svg`,
    start_charge: `${URL}start_charge.svg`,
    star_empty: `${URL}star_empty.svg`,
    star_filled: `${URL}star_filled.svg`,
    statsblock_revenue_icon: `${URL}statsblock_revenue_icon.svg`,
    statsblock_transaction_icon: `${URL}statsblock_transaction_icon.svg`,
    statsblock_utlization_icon: `${URL}statsblock_utlization_icon.svg`,
    statsblock_charging_icon: `${URL}statsblock_charging_icon.svg`,
    stop_charge: `${URL}stop_charge.svg`,
    statiq_icon: `${URL}statiq_icon.png`,
    sub: `${URL}sub.svg`,
    tata_ev_pay_icon: `${URL}tata_ev_pay_icon.svg`,
    tick_mark: `${URL}tick_mark.svg`,
    time: `${URL}time.svg`,
    timezone_icon: `${URL}timezone_icon.svg`,
    total_chargers: `${URL}total_chargers.svg`,
    total_charger_icon: `${URL}total_charger_icon.svg`,
    total_charging_sessions: `${URL}total_charging_sessions.svg`,
    total_stations: `${URL}total_stations.svg`,
    total_profit_loss: `${URL}total_profit_loss.svg`,
    transaction_dashboard_icon: `${URL}transaction_dashboard_icon.svg`,
    trash_icon: `${URL}trash_icon.svg`,
    unavailable_charger: `${URL}unavailable_charger.svg`,
    union_icon: `${URL}union_icon.svg`,
    units_consumed_dashboard_icon: `${URL}units_consumed_dashboard_icon.svg`,
    upload_icon: `${URL}upload_icon.svg`,
    users_dashboard_icon: `${URL}users_dashboard_icon.svg`,
    user_avatar: `${URL}user_avatar.svg`,
    user_icon: `${URL}user_icon.svg`,
    video: `${URL}video.svg`,
    wallet_credit: `${URL}wallet_credit.svg`,
    wallet_debit: `${URL}wallet_debit.svg`,
    wallet_icon: `${URL}wallet_icon.svg`,
    white_tick: `${URL}white_tick.svg`,
    white_edit_icon: `${URL}white_edit_icon.svg`,
    x: `${URL}x.svg`,
    "404_error_icon": `${URL}404_error_icon.svg`,
    "401_error_icon": `${URL}401_error_icon.svg`,
    table_download_icon: `${URL}table_download_icon.svg`,
    unique_transaction_icon: `${URL}uniquetransactioncustomers.svg`,
    active_admin_icon: `${URL}ActiveAdmins.svg`,
    engaged_user_icon: `${URL}EngagedUsers.svg`,
}
